<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="popup_filter = true">筛选</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff;" v-for="course in courses" :key="course.id">
					<div>{{course.title}}</div>
					<div v-if="course.created_at">{{course.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="标题 or 关键词 or 描述" clearable></el-input>
					</el-form-item>
					<el-form-item  v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
							<el-option label="创建时间" value="created"></el-option>
							<el-option label="更新时间" value="updated"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getCourses({ ...params, page: 1})">查询</el-button>
						<el-button type="primary" @click="course_dialog = true" :disabled="!$utils.create('courses')">创建课程</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="courses" :size="theme.size" @sort-change="sortChange">
					<el-table-column fixed="left" type="selection" width="42"></el-table-column>
					<el-table-column label="封面图" width="100">
						<el-image slot-scope="scope" class="course_img" :src="scope.row.cover" :preview-src-list="[scope.row.cover]">
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column>
					<el-table-column prop="id" label="名称/ID" sortable="custom">
						<template slot-scope="scope">
							<h3>{{scope.row.title}}</h3>
							<p>
								{{scope.row.id}}
								<span> · {{scope.row.course_chapters.length}} <el-button type="text" size="mini" @click="onChapter(scope.row)">章节</el-button></span>
								<span> · {{scope.row.duration}} 秒</span>
							</p>
							<div>
								<el-checkbox :size="theme.size" @change="changecourses({ action: 'publishing', id: scope.row.id})" :value="scope.row.published_at!=null" :disabled="!$utils.checkAuth('courses', 'publishing') || scope.row.deleted_at">发布</el-checkbox>
								<el-checkbox :size="theme.size" @change="changecourses({ action: 'topping', id: scope.row.id})" :value="scope.row.topped_at!=null" :disabled="!$utils.checkAuth('courses', 'topping') || scope.row.deleted_at">置顶</el-checkbox>
							</div>
							<p style="margin-top: 2px;">
								<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
							</p>					
						</template>
					</el-table-column>
					<el-table-column prop="views" label="SEO优化/浏览" sortable="custom">
						<template slot-scope="scope">
							<el-input v-model="scope.row.keywords" placeholder="请输入课程关键词" @change="submitCourses(scope.row)" :size="theme.size" style="margin-bottom: 5px" :readonly="!$utils.update('courses')" :disabled="scope.row.deleted_at" maxlength="48" show-word-limit>
								<template slot="prepend">{{scope.row.views}}</template>
							</el-input>
							<el-input type="textarea" rows="2" v-model="scope.row.description" placeholder="请输入课程描述" @change="submitCourses(scope.row)" :size="theme.size" :readonly="!$utils.update('courses')" :disabled="scope.row.deleted_at" maxlength="168" show-word-limit></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<template v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</template>
							<template v-else>更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('courses')" v-if="scope.row.deleted_at">恢复</el-button>
							<template v-else>
								<el-button type="text" @click="course = {...scope.row}; course_dialog = true;" :disabled="!$utils.update('courses')">编辑</el-button>
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('courses')">删除</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getCourses({...params, page: 1, perPage: v}) }"
					@current-change="(v) => { getCourses({ ...params, page: v}) }"
					background>
				</el-pagination>
			</main-table>
			<!-- 课程章节列表 -->
			<el-drawer ref="chapter_drawer" direction="rtl" :title="`《${course.title}》`+'课程章节'" :visible.sync="course_chapters_drawer" :size="theme.width <= 550 ? '100%' : '550px'" :close-on-press-escape="false" @closed="course = {};" destroy-on-close append-to-body>
				<div style="padding: 0 10px;">
					<el-collapse v-if="course.course_chapters && course.course_chapters.length" accordion>
						<el-collapse-item v-for="course_chapter in course.course_chapters" :name="course_chapter.id" :key="course_chapter.id">
							<div slot="title" style="width: 100%; display: flex; padding: 0 10px; justify-content: space-between;">
								<div>{{course_chapter.title}}</div>
								<div @click.stop.prevent="() => {}">
									<el-button size="mini" type="text" @click.stop.prevent="video_dialog = true ">添加视频</el-button>
									<el-button size="mini" type="text" @click.stop.prevent="chapter = {...course_chapter}; chapter_dialog = true; ">编辑</el-button>
									<el-popconfirm title="确定要删除这条记录吗？" @confirm="onDeleteChapter(course_chapter)">
										<el-button slot="reference" size="mini" type="text" style="margin-left: 10px; color: #ff0000">删除</el-button>
									</el-popconfirm>
								</div>
							</div>
							<div style="color: #666; font-size: 12px; padding: 0 10px;">{{course_chapter.description}}</div>
							<div style="padding: 10px;" v-if="course_chapter.videos && course_chapter.videos.length">
								<div class="video" v-for="video in course_chapter.videos" :key="video.id">
									<div class="poster" :style="'background-image: url('+video.poster+');'" @click="onVideo(video)">
										<i class="el-icon-video-play"></i>
									</div>
									<div class="desc">
										<h3>{{video.title}}</h3>
										<p>{{video.description}}</p>
										<div class="mate">{{video.duration}} 秒 · {{video.views}} 浏览 · 创建于 {{video.created_at}}</div>
									</div>
								</div>
							</div>
							<div v-else style="text-align: center; line-height: 40px; font-size: 12px; color: #999;">暂无视频~</div>
						</el-collapse-item>
					</el-collapse>
					<el-button size="mini" type="primary" style="margin-top: 20px;" @click="chapter = {course_id: course.id}; chapter_dialog = true;">添加</el-button>
				</div>
			</el-drawer>
			<!-- 编辑添加课程 -->
			<el-dialog width="450px" :title="course.id ? '更新课程' : '添加课程'" :visible.sync="course_dialog" :fullscreen="theme.width < 450" @closed="course = {}; $refs['course_form'].resetFields();">
				<el-form ref="course_form" label-position="top" :size="theme.size" :model="course" :rules="course_rules">
					<el-form-item prop="title" label="标题">
						<el-input placeholder="请输入课程标题" v-model="course.title" maxlength="38" show-word-limit clearable>
							<el-select slot="prepend" style="width: 120px;" v-if="!admin_user.shop_id" v-model="params.shop_id" placeholder="城市站点" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item prop="cover" label="封面">
						<el-upload
							class="avatar-uploader"
							:action="$api.URI_UPLOADS"
							:accept="course_thumb_cfg.ext.map((e) => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { !r.code ? (course = {...course, cover: r.result.url}) : $message.error(r.msg)}"
							:data="{type: course_thumb_cfg.type}"
							:show-file-list="false"
							:before-upload="beforeThumbAttach">
							<img v-if="course.cover" :src="course.cover" class="avatar">
							<div v-else class="avatar-uploader-icon" style="font-size: 18px;">点击上传封面</div>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('course_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<!-- 编辑添加章节 -->
			<el-dialog width="420px" :title="chapter.id ? '更新章节' : '添加章节'" :visible.sync="chapter_dialog" :fullscreen="theme.width < 420" @closed="chapter = {}; $refs['chapter_form'].resetFields();">
				<el-form ref="chapter_form" label-width="60px" :model="chapter" :rules="chapter_rules" :size="theme.size" status-icon>
					<el-form-item label="标题" prop="title">
						<el-input v-model="chapter.title" autocomplete="off" placeholder="请输入标题" maxlength="38" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input v-model="chapter.sort" autocomplete="off" placeholder="请输入排序" clearable></el-input>
					</el-form-item>
					<el-form-item label="描述" prop="description">
						<el-input v-model="chapter.description" type="textarea" rows="3" placeholder="请输入描述" maxlength="255" show-word-limit></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('chapter_form')">{{chapter.id ? '保存更新' : '提交添加'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<!-- 编辑添加视频 -->
			<el-dialog width="420px" :title="video.id ? '更新视频' : '添加视频'" :visible.sync="video_dialog" :fullscreen="theme.width < 420" @closed="video = {}; $refs['video_form'].resetFields();">
				<el-form ref="video_form" label-width="0px" :model="video" :rules="video_rules" :size="theme.size" status-icon>
					<el-form-item prop="title">
						<el-input v-model="video.title" autocomplete="off" placeholder="请输入标题" maxlength="38" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item v-if="video.url">
						<video-player ref="videoPlayer" :options="player_options" ></video-player>
					</el-form-item>
					<el-form-item prop="description">
						<el-input v-model="video.description" type="textarea" rows="3" placeholder="请输入描述" maxlength="255" show-word-limit></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('video_form')">{{video.id ? '保存更新' : '提交添加'}}</el-button>
						<el-popconfirm title="确定要删除这条视频记录吗？" @confirm="onDeleteVideo(video)" v-if="video.id">
							<el-button slot="reference" size="mini" type="danger" style="margin: 0 10px;">删除视频</el-button>
						</el-popconfirm>
						<el-button type="primary" @click="$message.error('编码开发中')" plain>上传视频</el-button>
						<el-button type="primary" v-if="video.url" @click="$message.error('编码开发中')" plain>上传封面</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</div>
</template>

<style type="text/css">
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		margin-right: 10px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 240px;
		height: 180px;
		line-height: 180px;
		text-align: center;
	}
	.avatar {
		width: 240px;
		height: 180px;
		display: block;
	}
	.course_img {
		width: 80px;
		height: 80px;
		font-size: 40px;
		line-height: 80px;
		text-align: center;
		background-color: #f1f1f1;
	}
	.view-tag {
		color: #fff;
		font-size: 12px;
		padding: 0 10px;
		background-color: rgba(0, 0, 0, .6);
		position: absolute;
		right: 0;
		top: 0;
	}
	.video {
		display: flex;
		color: #333;
		border-radius: 6px;
		margin-bottom: 10px;
		align-items: center;
		box-shadow: 0 0 5px 5px #f1f1f1;
	}
	.video:last-of-type {
		margin-bottom: 0px;
	}
	.video .poster {
		width: 100px;
		height: 70px;
		color: #fff;
		cursor: pointer;
		font-size: 20px;
		line-height: 70px;
		border-radius: 6px;
		margin-right: 10px;
		text-align: center;
		background-color: #ccc;
		background-size: cover;
	}
	.video .poster:hover {
		color: #ff0000;
	}
	.video .desc {
		flex: 1;
	}
	.video .desc p {
		max-width: 400px;
		color: #999;
		font-size: 12px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.video .desc .mate {
		color: #666;
		font-size: 12px;
	}
	.vjs-poster {
		background-size: cover;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import { videoPlayer } from 'vue-video-player';
	import 'video.js/dist/video-js.css';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			videoPlayer,
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			course_thumb_cfg () {
				return this.$utils.uploadConfig('course_thumb');
			},
			course_detail_cfg () {
				return this.$utils.uploadConfig('course_detail');
			},
			player_options () {
				const { url: src, poster } = this.video;
				return {
					poster,
					width: 380,
					playbackRates: [0.7, 1.0, 1.5, 2.0],
					sources: [{
						type: "video/mp4",
						src
					}]
				}
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getCourses(p);
			},
			beforeThumbAttach (file) {
				const { size, format_size_unit } = this.course_thumb_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传封面图不能超过 '+format_size_unit);
				return is;
			},
			async changecourses (r) {
				const res = await this.$http.get(this.$api.URI_COURSES, {params: r, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getCourses(this.params);
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'course_form':
							this.submitCourses(this.course);
						break;
						case 'chapter_form':
							if (!this.chapter.course_id) return this.$message.error("提交未知课程！");
							this.submitChapters(this.chapter);
						break;
						case 'video_form':
							this.submitVideos(this.video);
						break;
					}
				});
			},
			async submitVideos (data) {
				const res = await this.$http.post(this.$api.URI_VIDEOS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.onChapter(this.course);
						this.video_dialog = false;
					}
				});
			},
			async submitCourses (data) {
				const res = await this.$http.post(this.$api.URI_COURSES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getCourses(this.params);
						this.course_dialog = false;
					}
				});
			},
			async submitChapters (data) {
				const res = await this.$http.post(this.$api.URI_COURSES_CHAPTERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.onChapter(this.course);
						this.chapter_dialog = false;
					}
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.title+'】课程吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COURSES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getCourses(this.params);
						}
					});
				});
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.title+'】课程吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COURSES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getCourses(this.params);
						}
					});
				});
			},
			async onDeleteChapter (r) {
				const res = await this.$http.get(this.$api.URI_COURSES_CHAPTERS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getCourses(this.params);
						this.course_chapters_drawer = false;
					}
				});
			},
			async onDeleteVideo (r) {
				const res = await this.$http.get(this.$api.URI_VIDEOS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.onChapter(this.course);
						this.video_dialog = false;
					}
				});
			},
			onVideo (v) {
				this.video = {...v};
				this.video_dialog = true;
			},
			async onChapter (r) {
				const res = await this.$http.get(this.$api.URI_COURSES, {params: {action: 'update', id: r.id}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.course = { ...result };
				this.course_chapters_drawer = true;
			},
			async getCourses (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_COURSES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.courses = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				tags: [],
				shops: [],
				courses: [],
				video_dialog: false,
				course_dialog: false,
				chapter_dialog: false,
				course_chapters_drawer: false,
				video: {},
				course: {},
				chapter: {},
				params: {
					perPage: 10,
				},
				video_rules: {
					title: [{ required: true, message: '请输入课程标题', trigger: 'blur' }]
				},
				course_rules: {
					title: [{ required: true, message: '请输入课程标题', trigger: 'blur' }]
				},
				chapter_rules: {
					title: [{ required: true, message: '请输入章节标题', trigger: 'blur' }]
				},
				riqi: {
					// 参与用户（可重复2次）
					users: {'jfjghjg': 1, 'sdfjdjfh': 1},
					// 奖品
					prizes: [
						{
							// 名称
							name: '杂志',
							// 数量
							qty: 15,
							// 中奖
							users: ['jfjghjg', 'sdfjdjfh']
						}
					]
				}
				// 1.活动时间：2023年12月29日-2024年1月3日；
				// 2.参与者需为深圳IP地址，每人每天有2次答题机会；
				// 3.游戏将在80道题库里随机抽取10道与自然相关的选择题或判断题，答对1道获得10分，参与者需在规定时间10分钟内完成答题，如未在规定时间内完成答题，系统将自动提交；
				// 4.答对获得80分及以上即可参与互动抽奖，每人仅可中奖一次；
				// 5.中奖后，参与者需填写收件信息，方便接收奖品；
				// 6.活动最终解释权归活动方所有。
			}
		},
		created () {
			this.getCourses(this.params, true);
		}
	};
</script>

